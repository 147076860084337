import React, { useEffect, useState } from "react";
import emoji from "react-easy-emoji";

export default function EmojiLoading() {
   
  const [clockEmoji, setEmoji] = useState(emoji("🕐"));

  useEffect(() => {
    const arr = [
        "🕐",
        "🕜",
        "🕑",
        "🕝",
        "🕒",
        "🕞",
        "🕓",
        "🕟",
        "🕔",
        "🕠",
        "🕕",
        "🕡",
        "🕖",
        "🕢",
        "🕗",
        "🕣",
        "🕘",
        "🕤",
        "🕙",
        "🕥",
        "🕚",
        "🕦",
        "🕛",
        "🕧",
      ];
    setInterval(() => {
        setEmoji(emoji(arr[Math.floor(Math.random() * arr.length)]));
      }, 125);
  }, [])

  return (
    <div class="emoji-load">{clockEmoji}</div>
  );
}
